<template>
  <div>
    <el-form :model="myForm" ref="editGuiZeFormRef" :rules="rules" label-width="100px" size="small">
      <el-form-item label="规则名称" prop="name">
        <el-input v-model="myForm.name" clearable placeholder="请输入规则名称"></el-input>
      </el-form-item>

      <el-form-item label="公司名称" prop="tenantName">
        <el-select v-model="myForm.tenantName" clearable placeholder="请选择公司名称" @change="gongSiChange" :disabled="!!myForm.id">
          <el-option v-for="item in gongSiList" :key="item.companyCode" :label="item.companyName" :value="item.companyCode"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="规则类型" prop="type" v-if="myForm.tenantName">
        <el-select v-model="myForm.type" clearable placeholder="请选择规则类型">
          <el-option v-for="(item, index) in typeArr" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <div v-if="myForm.type == 1">
        <el-form-item label="条件" prop="">
          <div v-for="(item, index) in myForm.conditionList" :key="index">
            <el-input style="width: 100px; margin-left: 10px" v-model="item.dayn" placeholder=""></el-input>
            <span>天</span>

            <span style="margin-left: 10px">奖池</span>
            <el-select v-model="item.poolId" placeholder="请选择">
              <el-option v-for="item in jiangChiArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <i style="margin: 0px 10px 0 10px; font-size: 26px" class="el-icon-circle-plus-outline" @click="addClick"></i>
            <i style="font-size: 26px" class="el-icon-remove-outline" @click="delClick(item, index)"></i>
          </div>
        </el-form-item>
        <el-form-item label="中奖概率" prop="winRate">
          <el-input v-model="myForm.winRate" max="100" min="0" placeholder="请输入中奖概率"></el-input>
          %
        </el-form-item>
      </div>

      <el-form-item label="描述">
        <el-input :autosize="{ minRows: 3, maxRows: 6 }" type="textarea" v-model="myForm.description"></el-input>
      </el-form-item>

      <el-form-item>
        <div class="btnDiv">
          <el-button @click="quXiaoClick">取 消</el-button>
          <el-button type="primary" @click="diaQueDingClick">确 定</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/* eslint-disable */
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { makeForm, guiZeMeiJu } from '@/utils/tools.js'
export default {
  props: ['rowData'],
  components: { uploadImg },
  data() {
    return {
      myForm: {
        name: '', // 规则名称
        tenantName: '', // 公司名称
        type: '', // 规则类型
        conditionList: [{ dayn: '', poolId: '' }],
        description: '', // 描述
        winRate: '', // 中奖概率
        id: '',
      },
      gongSiList: [], // 公司数据源
      jiangChiArr: [], // 奖池数据源
      // 规则类型数据源
      typeArr: guiZeMeiJu,
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        tenantName: [{ required: true, message: '请选择公司', trigger: 'change' }],
        type: [{ required: true, message: '请选择规则类型', trigger: 'change' }],
        winRate: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              console.log('rule', rule, value)
              if (!value) {
                return callback(new Error('请输入中奖概率'))
              }
              if (isNaN(value)) {
                return callback(new Error('请输入数字值'))
              } else {
                if (value < 0 || value > 100) {
                  return callback(new Error('请输入0到100的数字'))
                } else {
                  return callback()
                }
              }
            },
          },
        ],
      },
    }
  },
  created() {},
  watch: {
    rowData() {
      this.init()
    },
  },
  mounted() {
    this.init()
    // 获取公司列表
    this.getGongSiList()
  },
  methods: {
    init() {
      if (this.rowData) {
        // 获取奖池数据源
        this.getJiangChiList(this.rowData.tenantName)
        let tmp = JSON.parse(JSON.stringify(this.rowData))
        console.log('tmp', tmp)
        makeForm(this.myForm, tmp)
      }
      console.log('this.myForm', this.myForm)
    },
    addClick() {
      if (this.myForm.conditionList.length >= 5) {
        this.$message.warning('规则最多只能存在5条')
      } else {
        let obj = { dayn: '', tenantName: '', poolId: '' }
        this.myForm.conditionList.push(obj)
      }
    },
    delClick(item, index) {
      if (this.myForm.conditionList.length <= 1) {
        this.$message.warning('规则最少需要1条')
      } else {
        if (this.myForm.conditionList.length > 0) {
          this.myForm.conditionList.splice(index, 1)
        }
      }
    },
    // 获取公司列表
    async getGongSiList() {
      const res = await this.$API.gongSi.get()
      // console.log('getGongSiList', res)
      let { status, data = [], msg = '' } = res
      if (status == 200) {
        this.gongSiList = data
      } else {
        this.$message.warning(msg)
      }
    },
    // 公司改变之后 查询奖池数据源
    gongSiChange(val) {
      console.log('公司val', val)
      // return
      if (val) {
        // 获取奖池列表
        this.getJiangChiList(val)
      } else {
        this.myForm.type = ''
        this.myForm.conditionList = [{ dayn: '', poolId: '' }]
      }
    },
    // 获取奖池列表
    async getJiangChiList(val) {
      const res = await this.$API.jiangChi.getPoolList({ tenantName: val })
      // console.log('getGongSiList', res)
      let { status, data = [], msg = '' } = res
      if (status == 200) {
        this.jiangChiArr = data
      } else {
        this.$message.warning(msg)
      }
    },

    // 确定
    async diaQueDingClick() {
      this.$refs.editGuiZeFormRef.validate(async (valid) => {
        if (valid) {
          // 编辑
          if (this.rowData) {
            this.myForm.conditionList.forEach((t) => {
              t.activityId = this.myForm.id
            })
            const res = await this.$API.guiZe.update(this.myForm)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$emit('diaQueDingClick_gz')
          } else {
            const res = await this.$API.guiZe.add(this.myForm)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$emit('diaQueDingClick_gz')
          }
        }
      })
    },
    // 取消
    quXiaoClick() {
      this.$emit('quXiaoClick_gz')
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.btnDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
