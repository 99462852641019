<template>
  <div>
    <el-card>
      <div class="topDiv">
        <el-input v-model="params.name" placeholder="请输入"></el-input>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addInfoClick()">添加</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="规则名称" min-width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="tenantName" label="公司名称" min-width="150" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="mtName" label="类型" min-width="100" align="center">
          <template slot-scope="scope">{{ leiXingStr(scope.row) }}</template>
        </el-table-column>
        <el-table-column prop="mtName" label="条件" min-width="150" show-overflow-tooltip align="center">
          <template slot-scope="scope">{{ showTiaoJianStr(scope.row) }}</template>
        </el-table-column>
        <el-table-column prop="mtName" label="奖池" min-width="200" show-overflow-tooltip align="center">
          <template slot-scope="scope">{{ showJiangChiStr(scope.row) }}</template>
        </el-table-column>
        <el-table-column prop="winRate" label="中奖概率" min-width="70" align="center">
          <template slot-scope="scope">{{ scope.row.winRate }}%</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column label="操作" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!--  新增活动/ 修改活动-->
    <el-dialog :title="rowData ? '修改规则' : '新增规则'" :visible.sync="guiZeDia" v-if="guiZeDia" width="800px" @close="guiZeDia = false">
      <guiZeDia :rowData="rowData" @quXiaoClick_gz="guiZeDia = false" @diaQueDingClick_gz="diaQueDingClick_gz"></guiZeDia>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import guiZeDia from '@/components/guiZeDia.vue'
import { makeForm, guiZeMeiJu } from '@/utils/tools.js'

export default {
  components: { pagination, guiZeDia },
  mixins: [commonMixin],
  data() {
    return {
      guiZeDia: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        name: '',
      },
      records: [],
      total: 0,
      rowData: '',
      jiangChiArr: [], // 奖池数据源
    }
  },
  mounted() {
    // 获取所有奖池
    // this.getAllJiangChi()
    this.getData()
  },
  methods: {
    // 搜索
    searchClick() {
      this.params.pageNum = 1
      this.getData()
    },
    //
    async getData() {
      const res = await this.$API.guiZe.getGuiZeList(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = +total
    },

    // 添加
    addInfoClick() {
      this.rowData = ''
      this.guiZeDia = true
    },
    // 编辑
    updateClick(item) {
      this.guiZeDia = true
      this.rowData = item
    },
    // 弹框上 确定 click
    async diaQueDingClick_gz() {
      this.guiZeDia = false
      this.getData()
    },

    //  删除
    async deleteItem(item) {
      const params = {
        id: item.id,
        tenantName: item.tenantName,
      }

      this.$confirm('此操作将永久删除该规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.guiZe.delGuiZe(params)
            if (res.status !== 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
            // resolve(true)
          }
        })
        .catch((err) => err)
    },

    // 类型toStr
    leiXingStr(row) {
      let item = guiZeMeiJu.find((e) => e.value == row.type) || {}
      return item.label || '--'
    },
    // 回显条件
    showTiaoJianStr(row) {
      // console.log('===', row)
      let arr = row.conditionList || []
      let str = ''
      arr.forEach((el) => {
        str = str + el.dayn + '天，'
      })
      if (str.length > 0) {
        str = str.substring(0, str.length - 1)
      }
      return str
    },

    // 回显奖池
    showJiangChiStr(row) {
      let arr = row.conditionList || []
      let str = ''
      arr.forEach((el) => {
        str = str + el.poolName + '，'
      })
      if (str.length > 0) {
        str = str.substring(0, str.length - 1)
      }
      return str
    },
  },
}
</script>

<style lang="scss" scoped>
.topDiv {
  display: flex;
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
